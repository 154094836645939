import styles from '../css/app.css';

// App main
const main = async () => {
    // Import our CSS
    //const Styles = await import(/* webpackChunkName: "styles" */ '../css/app.pcss');
    // Async load the vue module
    const Vue = await import(/* webpackChunkName: "vue" */ 'vue');

    // Create our vue instance
    const vm = new Vue.default({
        el: "#app",
        components: {
          'cta-form': () => import(/* webpackChunkName: "cta-form" */ '../vue/CTAForm.vue'),
          'google-reviews': () => import(/* webpackChunkName: "google-reviews" */ '../vue/GoogleReviews.vue'),
          'inquiries-wizard': () => import(/* webpackChunkName: "inquiries-wizard" */ '../vue/InquiriesWizard.vue'),
          'mobile-navigation': () => import(/* webpackChunkName: "mobile-navigation" */ '../vue/MobileNavigation.vue'),
        },
        data: {
          scrolled: false,
          topPosition: 0
        },
        methods: {
          handleScroll() {
            this.scrolled = window.scrollY > 100
            this.topPosition = window.scrollY
          },
        },
        beforeMount() {
          window.addEventListener('scroll', this.handleScroll)
        },
        beforeDestroy() {
          window.removeEventListener('scroll', this.handleScroll)
        }
    });
};
// Execute async function
main().then( (value) => {
});
